import { useEffect, useState } from 'react';
import { Button, Modal, Tooltip } from 'flowbite-react';
import { HiOutlinePlus } from 'react-icons/hi';
import QRScanner from './QRScanner/QRScanner';
import { useAppSelector } from '../../Reducers/store';
import { Event } from '../../Models/Event';
import { toast } from 'react-toastify';
import EventApi from '../../Api/EventApi';
import EventsListTable from './EventsListTable';
import Loader from '../Common/Loader';
import AddEditEventModal from './AddEditEventModal';
import EventParticipantsModal from './Participants/EventParticipantsModal';
import UserApi from '../../Api/UserApi';

export default function Events() {
  const [showQRScanner, setshowQRScanner] = useState<boolean>(false);
  const { userModel } = useAppSelector((state) => state.user);
  const [eventListData, seteventListData] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showEvent, setshowEvent] = useState<boolean>(false);
  const [showParticipants, setshowParticipants] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  useEffect(() => {
    getEvents();
  }, [userModel]);

  const getEvents = () => {
    try {
      setIsLoading(true);
      return EventApi.getEventList()
        .then((res) => {
          seteventListData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error('Error');
          console.error(err);
          setIsLoading(false);
        });
    } catch (error) {
      toast.error('Error');
      console.error(error);
      setIsLoading(false);
    }
  };

  function onCloseModal() {
    setshowQRScanner(false);
  }

  const handleAddUser = (ev: Event) => {
    setSelectedEvent(ev);
    setshowQRScanner(true);
  };
  const handleAddUserSave = () => {
    getEvents();
    setSelectedEvent(null);
    onCloseModal();
  };
  const handleEditEvent = (ev: Event | null) => {
    setSelectedEvent(ev);
    setshowEvent(true);
  };
  const handleShowParticipants = (ev: Event | null) => {
    setSelectedEvent(ev);
    setshowParticipants(true);
  };
  const handleDeleteEvent = (ev: Event) => {
    const shouldRemove = window.confirm(
      'Are you sure you want to delete this Event?'
    );

    if (shouldRemove) {
      return EventApi.deleteEvent(ev.id)
        .then((res) => {
          if (res.data.success) toast.success('Event Deleted');
          getEvents();
        })
        .catch((err) => {
          toast.error('Error');
          console.error(err);
        });
    }
  };
  const getEventReport = async (ev: Event) => {
    try {
      setIsLoading(true);
      const response = await EventApi.getEventReport(ev.id);

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a temporary <a> element to trigger the download
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', `${ev.name}_Report.pdf`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setIsLoading(false);
    }
  };

  const qrScanner =
    showQRScanner && selectedEvent ? (
      <QRScanner
        selectedEvent={selectedEvent}
        handleAddUserSave={handleAddUserSave}
      />
    ) : null;
  return (
    <>
      <div className='float-right pr-6'>
        <Tooltip content='Add Event'>
          <Button onClick={() => handleEditEvent(null)} className='buttonMain'>
            <HiOutlinePlus />
          </Button>
        </Tooltip>
      </div>
      <div className='w-full'>
        <div className='w-full p-4'>
          {isLoading && <Loader />}
          <EventsListTable
            data={eventListData}
            handleEditEvent={handleEditEvent}
            handleAddUser={handleAddUser}
            deleteEvent={handleDeleteEvent}
            handleShowParticipants={handleShowParticipants}
            handleDownloadReport={getEventReport}
          />
        </div>
        <AddEditEventModal
          show={showEvent}
          setShow={setshowEvent}
          selectedEvent={selectedEvent}
          setselectedEvent={setSelectedEvent}
          getEvents={getEvents}
        />
      </div>
      <Modal show={showQRScanner} size='md' onClose={onCloseModal} popup>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div>{qrScanner}</div>
        </Modal.Body>
      </Modal>
      <EventParticipantsModal
        show={showParticipants}
        setShow={setshowParticipants}
        selectedEvent={selectedEvent}
      />
    </>
  );
}
