import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Event } from '../../Models/Event';
import { displayDateOnly } from '../../Utils';

export default function UserEventsListTable({ data }: { data: Event[] }) {
  const columnHelper = createColumnHelper<Event>();

  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.eventDate, {
      id: 'eventDate',
      header: 'Date',
      cell: (info) =>
        info.getValue() === null
          ? ''
          : displayDateOnly(new Date(info.getValue()!)),
      footer: (info) => info.column.id,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      sortingFn: 'alphanumeric',
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      header: 'Description',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [
        {
          id: 'eventDate',
          desc: true,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
