import axios from 'axios';
import { API_URL, getAuthHeader } from '.';
import { ResponseModel } from '../Models/ResponseModel';
import { DisplayAd } from '../Models/DisplayAd';
import { DisplayAdEvent } from '../Models/DisplayAdEvent';
import { DisplayAdEventResult } from '../Models/DisplayAdEventResult';

class DisplayAdsApi {
  static getDisplayAd(id: string) {
    return axios.get<DisplayAd>(
      `${API_URL}/api/displayads/${id}`,
      getAuthHeader()
    );
  }
  static getDisplayAds() {
    return axios.get<DisplayAd[]>(
      `${API_URL}/api/displayads/GetDisplayAds/`,
      getAuthHeader()
    );
  }
  static getActiveDisplayAds() {
    return axios.get<DisplayAd[]>(
      `${API_URL}/api/displayads/GetActiveDisplayAds/`,
      getAuthHeader()
    );
  }
  static addUpdateDisplayAd(request: DisplayAd) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/displayads/`,
      request,
      getAuthHeader()
    );
  }
  static uploadDisplayAdImage(request: FormData) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/displayads/imageupload`,
      request,
      getAuthHeader()
    );
  }
  static getMemberDisplayAds(memberId: string) {
    return axios.get<DisplayAd[]>(
      `${API_URL}/api/displayads/getMemberDisplayAds/${memberId}`,
      getAuthHeader()
    );
  }
  static getMemberDisplayAdsPublic(memberId: string) {
    return axios.get<DisplayAd[]>(
      `${API_URL}/api/displayads/getMemberDisplayAds/${memberId}/public`
    );
  }
  static getDisplayAdEventResults(displayAdId: number) {
    return axios.get<DisplayAdEventResult[]>(
      `${API_URL}/api/displayads/GetDisplayAdEventResults/${displayAdId}`,
      getAuthHeader()
    );
  }
  static addDisplayEventClick(request: DisplayAdEvent) {
    return axios.post<boolean>(
      `${API_URL}/api/displayads/AddDisplayEventClick/`,
      request,
      getAuthHeader()
    );
  }
  // static deleteDisplayAd(id: number) {
  //   return axios.delete<ResponseModel>(
  //     `${API_URL}/api/displayads/${id}`,
  //     getAuthHeader()
  //   );
  // }
}

export default DisplayAdsApi;
