import 'devextreme/dist/css/dx.light.css';
import './App.css';
import { Outlet } from 'react-router-dom';
import TopNavigation from './Components/Navigation/TopNav';
import SideNav from './Components/Navigation/SideNav';
import ReactGA from 'react-ga4';
import { useState } from 'react';
ReactGA.initialize('G-MDSYKZ11YQ');

function App() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <TopNavigation setShow={setIsOpen}></TopNavigation>
      <div className='flex'>
        <SideNav show={isOpen} handleClose={handleClose}></SideNav>
        <Outlet></Outlet>
      </div>
    </>
  );
}

export default App;
