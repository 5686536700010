import axios from 'axios';
import { API_URL, getAuthHeader, getPDFAuthHeader } from '.';
import { ResponseModel } from '../Models/ResponseModel';
import { Event } from '../Models/Event';
import { User } from '../Models/User';
import { EventParticipant } from '../Models/EventParticipant';

class EventApi {
  static getEvent(id: string) {
    return axios.get<Event>(`${API_URL}/api/event/${id}`, getAuthHeader());
  }
  static getEventParticipant(id: number, userId: number) {
    return axios.get<EventParticipant>(
      `${API_URL}/api/event/${id}/participant/${userId}`,
      getAuthHeader()
    );
  }
  static getEventList() {
    return axios.get<Event[]>(`${API_URL}/api/event/list`, getAuthHeader());
  }
  static getUserEventList(userId: string) {
    return axios.get<Event[]>(
      `${API_URL}/api/event/list/user/${userId}`,
      getAuthHeader()
    );
  }
  static addUpdateEvent(request: Event) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/event`,
      request,
      getAuthHeader()
    );
  }
  static deleteEvent(id: number) {
    return axios.delete<ResponseModel>(
      `${API_URL}/api/event/${id}`,
      getAuthHeader()
    );
  }
  static addUserToEvent(eventId: number, request: User) {
    return axios.post<ResponseModel>(
      `${API_URL}/api/event/${eventId}`,
      request,
      getAuthHeader()
    );
  }
  static getEventReport(eventId: number) {
    return axios.get(
      `${API_URL}/api/Reports/event/${eventId}`,
      getPDFAuthHeader()
    );
  }
}

export default EventApi;
