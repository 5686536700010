import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './Components/Dashboard/Dashboard';
import { Provider } from 'react-redux';
import { store } from './Reducers/store';
import MemberProfile from './Components/Member/MemberProfile';
import EditMemberProfile from './Components/Member/EditMemberProfile';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { fetchCurrentUser, setUserFromToken } from './Actions/index';
import { isAfter, toDate } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import UserProfile from './Components/User/UserProfile';
import EditUserProfile from './Components/User/EditUserProfile';
import UserList from './Components/User/UserList';
import Login from './Components/User/Login';
import RequireAuth from './Components/Auth/RequireAuth';
import MemberList from './Components/Member/MemberList';
import MemberDirectory from './Components/Member/MemberDirectory';
import ErrorPage from './Components/Common/ErrorPage';
import { DecodedToken } from './Models/DecodedToken';
import InvoiceList from './Components/Member/InvoiceList';
import MemberResources from './Components/Member/MemberResources';
import ResetPassword from './Components/User/ResetPassword';
import ForgotPassword from './Components/User/ForgotPassword';
import CustomSitePage from './Components/Member/CustomSitePage';
import MemberApplication from './Components/MembershipApplication/MemberApplication';
import ThankYou from './Components/MembershipApplication/ThankYou';
import NewApplicant from './Components/MembershipApplication/NewApplicant';
import AppPublic from './AppPublic';
import MemberTags from './Components/Member/MemberTags';
import DownloadFiles from './Components/Member/DownloadFiles';
import FallConvention from './Components/Member/FallConvention';
import SpringMeeting from './Components/Member/SpringMeeting';
import IndustryShowcase from './Components/Member/IndustryShowcase';
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import MemberProfilePublic from './Components/Member/MemberProfilePublic';
import UserProfilePublic from './Components/User/UserProfilePublic';

config({ licenseKey });

const router = createBrowserRouter([
  {
    element: <AppPublic />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Login />,
        path: '/Login',
      },
      {
        element: <ForgotPassword />,
        path: '/forgotpassword',
        errorElement: <ErrorPage />,
      },
      {
        element: <ResetPassword />,
        path: '/resetpassword/:guid',
        errorElement: <ErrorPage />,
      },
      {
        path: '/memberapplication/apply',
        element: <MemberApplication />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/memberapplication/newapplicant/:subscriptionId',
        element: <NewApplicant />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/memberapplication/thankyou',
        element: <ThankYou />,
      },
      {
        path: '/member/:name/:id',
        element: <MemberProfilePublic />,
      },
      {
        path: '/user/:name/:id',
        element: <UserProfilePublic />,
      },
    ],
  },
  {
    element: (
      <RequireAuth>
        <App />
      </RequireAuth>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/members',
        element: <MemberList />,
      },
      {
        path: '/members/tags',
        element: <MemberTags />,
      },
      {
        path: '/members/directory',
        element: <MemberDirectory />,
      },

      {
        path: '/member/files',
        element: <DownloadFiles />,
      },
      {
        path: '/members/resources',
        element: <MemberResources />,
      },
      {
        path: '/members/fallconvention',
        element: <FallConvention />,
      },
      {
        path: '/members/springmeeting',
        element: <SpringMeeting />,
      },
      {
        path: '/members/industryshowcase',
        element: <IndustryShowcase />,
      },
      {
        path: '/member/:id',
        element: <MemberProfile />,
      },
      {
        path: '/edit/member/:id',
        element: <EditMemberProfile />,
      },
      {
        path: '/users',
        element: <UserList />,
      },
      {
        path: '/user/:id',
        element: <UserProfile />,
      },
      {
        path: '/edit/user/:id',
        element: <EditUserProfile />,
      },
      {
        path: '/member/invoices',
        element: <InvoiceList />,
      },
      {
        path: '/page/:id',
        element: <CustomSitePage />,
      },
    ],
  },
]);

const token = localStorage.getItem('EMDAToken');
if (token) {
  const decoded: DecodedToken = jwtDecode(token);
  const now = new Date();
  store.dispatch(
    setUserFromToken({
      email:
        decoded[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
        ],
      exp: decoded.exp,
    })
  );
  const isValid = isAfter(toDate(decoded.exp * 1000), now);
  if (isValid) {
    store.dispatch(fetchCurrentUser());
  }
}

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <RouterProvider router={router} />
    <ToastContainer
      position='bottom-center'
      autoClose={2000}
      limit={3}
      closeOnClick
      theme='dark'
    />
  </Provider>
);
