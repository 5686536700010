import { User } from '../../Models/User';
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
// import { Link } from 'react-router-dom';
import { HiDownload, HiUser } from 'react-icons/hi';
// import React from 'react';
import { Invoice } from '../../Models/Invoice';
import { Button, Checkbox } from 'flowbite-react';
import { displayDateOnly } from '../../Utils';
import InvoiceApi from '../../Api/InvoiceApi';
import { useState } from 'react';
import Loader from '../Common/Loader';
export default function InvoiceListTable({ data }: { data: Invoice[] }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const downloadPdf = async (invoiceId: string) => {
    try {
      setIsLoading(true);
      const response = await InvoiceApi.getInvoiceDownload(invoiceId);
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', `Invoice.pdf`);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setIsLoading(false);
    }
  };

  const columnHelper = createColumnHelper<Invoice>();

  const columns = [
    columnHelper.accessor((row) => row.refNumber, {
      id: 'download',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Button
            className='buttonMain p-2'
            onClick={() => downloadPdf(info.getValue())}
            size={20}
          >
            <HiDownload />
          </Button>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
    // columnHelper.accessor((row) => row.invoiceNumber, {
    //   id: 'invoiceNumber',
    //   header: 'Invoice Number',
    //   // cell: (info) => <i>{info.getValue()}</i>,
    //   footer: (info) => info.column.id,
    //   //filterFn: 'includesString',
    //   filterFn: (row, id, value) => {
    //     return value.includes(row.getValue(id));
    //   },
    // }),
    columnHelper.accessor((row) => row.transactionDate, {
      id: 'transactionDate',
      header: 'Transaction Date',
      // cell: (info) => <i>{info.getValue()}</i>,
      cell: (info) =>
        info.getValue() === null ? '' : displayDateOnly(info.getValue()),
      footer: (info) => info.column.id,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    }),
    columnHelper.accessor((row) => row.refNumber, {
      id: 'refNumber',
      header: 'Ref Number',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    }),
    columnHelper.accessor((row) => row.customerMemo, {
      id: 'customerMemo',
      header: 'Customer Memo',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    }),
    columnHelper.accessor((row) => row.totalAmt, {
      id: 'totalAmt',
      header: 'Total Amount',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.paidAmount, {
      id: 'paidAmount',
      header: 'Paid Amount',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.balance, {
      id: 'balance',
      header: 'Balance',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.isPaid, {
      id: 'isPaid',
      header: 'Is Paid',
      cell: (info) => (
        <Checkbox checked={info.getValue()} readOnly={true}></Checkbox>
      ),
      footer: (info) => info.column.id,
      enableColumnFilter: true,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    }),
    // columnHelper.accessor((row) => row.emailAddress, {
    //   id: 'emailAddress',
    //   header: 'Email',
    //   // cell: (info) => <i>{info.getValue()}</i>,
    //   footer: (info) => info.column.id,
    // }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        {isLoading && <Loader />}
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
