import { useEffect, useState } from 'react';
import blankImage from '../../Assets/blank.png';
import { useParams } from 'react-router-dom';
import UserApi from '../../Api/UserApi';
import { User } from '../../Models/User';
import { MdEmail, MdLocalPhone, MdLocationPin } from 'react-icons/md';
import DOMPurify from 'isomorphic-dompurify';
import Loader from '../Common/Loader';

export default function UserProfilePublic() {
  const { id } = useParams();
  const [userData, setUserData] = useState<User | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUser = () => {
    if (id) {
      setIsLoading(true);
      UserApi.getUserPublic(id)
        .then((res) => {
          setUserData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setUserData(null);
    }
  };

  useEffect(() => {
    getUser();
  }, [id]);

  const iconstyle = { color: '#5b9d30' };

  return (
    <>
      {isLoading && <Loader />}
      <div className='w-full'>
        <div>
          <div className='w-full p-2'>
            <div className='lg:flex no-wrap md:-mx-2 '>
              <div className='w-full lg:w-1/4 md:mx-2'>
                <div className='rounded-md'>
                  <img
                    src={userData?.image?.url ?? blankImage}
                    alt='...'
                    className='object-contain justify-self-center h-72'
                  />
                </div>
              </div>
              <div className='w-full lg:w-3/4 mx-2'>
                <div className='bg-white p-3'>
                  <div className='flex flex-col lg:flex-row items-end space-x-2 font-bold text-black py-2'>
                    <div className='w-full'>
                      <span className='tracking-wide uppercase text-3xl'>
                        {userData?.firstName} {userData?.lastName}
                      </span>
                    </div>
                  </div>
                  <hr className='bg-gray-300 h-[5px]'></hr>
                  <div className='text-gray-500'>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocalPhone style={iconstyle} />
                        </div>
                        <div className='font-bold'>Phone:</div>
                      </div>
                      <div className='pl-1 text-mainButton'>
                        {userData?.phoneNumber}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdEmail style={iconstyle} />
                        </div>
                        <div className='font-bold'>Email:</div>
                      </div>
                      <div className='pl-1 text-mainButton'>
                        {userData?.emailAddress}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Address:</div>
                      </div>
                      <div className='pl-1 text-mainButton'>
                        {userData?.address} {userData?.city}, {userData?.state}{' '}
                        {userData?.zip}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='flex flex-col md:grid md:grid-cols-[25%_75%] xl:grid-cols-[14%_86%] py-2 text-xl'>
                      <div className='flex'>
                        {/* <div className='px-1 content-center'>
                        <FaTwitter style={iconstyle} />
                      </div> */}
                        <div className='font-bold'>Bio:</div>
                      </div>
                      <div className='pl-1 text-mainButton whitespace-pre-line'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(userData?.bio ?? ''),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End of about section --> */}
                {/* <!-- End of profile tab --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
