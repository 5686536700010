export let API_URL: string;

if (process.env.NODE_ENV === 'production') {
  // API_URL = 'https://localhost:7228';
  API_URL = 'https://emdaapi.azurewebsites.net';
} else {
  // API_URL = 'https://localhost:44358';
  API_URL = 'https://localhost:7228';
  // API_URL = 'https://emdaapi.azurewebsites.net';
}

export const getAuthHeader = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('EMDAToken')}` },
  };
};
export const getPDFAuthHeader = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem('EMDAToken')}` },
    responseType: 'blob' as 'text',
  };
};
