import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import './QRScanner.scss';
import { Event } from '../../../Models/Event';
import { Button } from 'flowbite-react';
import { toast } from 'react-toastify';
import { User } from '../../../Models/User';
import UserApi from '../../../Api/UserApi';
import EventApi from '../../../Api/EventApi';
import { EventParticipant } from '../../../Models/EventParticipant';
import { displayDateOnly } from '../../../Utils';

export default function QRScanner({
  selectedEvent,
  handleAddUserSave,
}: {
  selectedEvent: Event | null;
  handleAddUserSave: () => void;
}) {
  const videoElementRef = useRef(null);
  const [scanned, setScannedText] = useState('');
  const [userId, setUserId] = useState<number>(0);
  const [currentUser, setcurrentUser] = useState<User | null>(null);
  const [currentParticipant, setcurrentParticipant] =
    useState<EventParticipant | null>(null);

  useEffect(() => {
    const video: HTMLVideoElement = videoElementRef.current!;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        const resultArray = result.data.split('/');
        if (resultArray.length === 6) {
          const linkType = resultArray[3];
          if (linkType !== 'user') {
            setScannedText('Invalid Link');
            setUserId(0);
            setcurrentUser(null);
            setcurrentParticipant(null);
          } else {
            const tempUserId = resultArray[5];
            setUserId(Number(tempUserId));
            setScannedText(result.data);
          }
        } else {
          setScannedText('Invalid Link');
          setUserId(0);
          setcurrentUser(null);
          setcurrentParticipant(null);
        }
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();

    return () => {
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  useEffect(() => {
    if (selectedEvent && userId > 0) {
      UserApi.getUser(userId.toString())
        .then((res) => {
          setcurrentUser(res.data);
          EventApi.getEventParticipant(selectedEvent.id, userId)
            .then((res2) => {
              setcurrentParticipant(res2.data);
            })
            .catch((err2) => {
              console.log(err2);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  const addUserToEvent = () => {
    if (currentUser && selectedEvent) {
      EventApi.addUserToEvent(selectedEvent.id, currentUser)
        .then((res) => {
          if (res.data.success) {
            setUserId(0);
            setcurrentUser(null);
            setcurrentParticipant(null);
            handleAddUserSave();
            toast.success('Success!');
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const buttonDisabled = () => {
    if (currentParticipant) return true;
    if (currentUser === null || selectedEvent === null) return true;
    return false;
  };

  return (
    <div>
      <p className='scannedText'>Event: {selectedEvent?.name}</p>
      <div className='videoWrapper'>
        <video className='qrVideo' ref={videoElementRef} />
      </div>
      {scanned && <p className='scannedText'>URL: {scanned}</p>}
      {currentUser && (
        <p className='scannedText'>
          User: {currentUser?.firstName} {currentUser?.lastName}
        </p>
      )}
      {currentParticipant && (
        <p className='scannedText'>
          Added on: {displayDateOnly(new Date(currentParticipant.dateCreated!))}
        </p>
      )}
      <div className='float-right'>
        <Button
          type='button'
          className='buttonMain'
          onClick={() => addUserToEvent()}
          disabled={buttonDisabled()}
        >
          Add User
        </Button>
      </div>
    </div>
  );
}
