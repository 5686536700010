import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Event } from '../../Models/Event';
import { displayDateOnly } from '../../Utils';
import {
  HiEye,
  HiOutlineDocumentReport,
  HiPencilAlt,
  HiXCircle,
} from 'react-icons/hi';
import { Button } from 'flowbite-react';
import { MdOutlinePersonAddAlt } from 'react-icons/md';

export default function EventsListTable({
  data,
  handleEditEvent,
  handleAddUser,
  deleteEvent,
  handleShowParticipants,
  handleDownloadReport,
}: {
  data: Event[];
  handleEditEvent: (ev: Event) => void;
  handleAddUser: (ev: Event) => void;
  deleteEvent: (ev: Event) => void;
  handleShowParticipants: (ev: Event) => void;
  handleDownloadReport: (ev: Event) => void;
}) {
  const columnHelper = createColumnHelper<Event>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Button
            className='buttonMain p-2'
            onClick={() => handleEditEvent(info.row.original)}
            size={20}
          >
            <HiPencilAlt />
          </Button>
          <Button
            onClick={() => handleAddUser(info.row.original)}
            className='buttonMainBlue p-2'
            size={20}
          >
            <MdOutlinePersonAddAlt />
          </Button>
          <Button
            onClick={() => handleDownloadReport(info.row.original)}
            className='buttonMain p-2'
            size={20}
          >
            <HiOutlineDocumentReport />
          </Button>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 120,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.eventDate, {
      id: 'eventDate',
      header: 'Date',
      cell: (info) =>
        info.getValue() === null
          ? ''
          : displayDateOnly(new Date(info.getValue()!)),
      footer: (info) => info.column.id,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      sortingFn: 'alphanumeric',
    }),
    columnHelper.accessor((row) => row.id, {
      id: 'participantCountId',
      header: 'Participants',
      cell: (info) => (
        <i
          className='flex gap-4 items-center cursor-pointer text-blue-600'
          onClick={() => handleShowParticipants(info.row.original)}
        >
          <HiEye />
          {info.row.original.eventParticipants
            ? info.row.original.eventParticipants.length
            : 0}
        </i>
      ),
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.id, {
      id: 'delete',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Button
            className='buttonCancel p-2'
            onClick={() => deleteEvent(info.row.original)}
            size={20}
          >
            <HiXCircle />
          </Button>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [
        {
          id: 'eventDate',
          desc: true,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
