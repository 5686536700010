import { Button, Modal, Textarea, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import Loader from '../Common/Loader';
import { Event } from '../../Models/Event';
import { formatDateInputs } from '../../Utils';
import EventApi from '../../Api/EventApi';
import { toast } from 'react-toastify';
import QRScanner from './QRScanner/QRScanner';
import { MdOutlinePersonAddAlt } from 'react-icons/md';

export default function AddEditEventModal({
  show,
  setShow,
  selectedEvent,
  setselectedEvent,
  getEvents,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: Event | null;
  setselectedEvent: React.Dispatch<React.SetStateAction<Event | null>>;
  getEvents: () => Promise<void> | undefined;
}) {
  const [showQRScanner, setshowQRScanner] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Event>({
    id: 0,
    name: null,
    eventDate: null,
    description: null,
    dateCreated: null,

    eventParticipants: [],
  });

  const handleSetInitialValues = () => {
    const values = selectedEvent
      ? {
          ...selectedEvent,
        }
      : {
          id: 0,
          name: null,
          eventDate: null,
          description: null,
          dateCreated: null,

          eventParticipants: [],
        };
    setFormData(values as Event);
  };

  useEffect(() => {
    if (show) handleSetInitialValues();
  }, [show]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    setButtonDisabled(true);
    e.preventDefault();
    return EventApi.addUpdateEvent(formData)
      .then((res) => {
        if (res.data.success) {
          toast.success('Success');
          setIsLoading(false);
          setButtonDisabled(false);
          getEvents();
          onCloseModal();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error('Error: ' + err);
        setIsLoading(false);
        setButtonDisabled(false);
      });
  };

  function onCloseModal() {
    const values = {
      id: 0,
      name: null,
      eventDate: null,
      description: null,
      dateCreated: null,

      eventParticipants: [],
    };

    setFormData(values as Event);
    setShow(false);
    setselectedEvent(null);
  }
  const handleAddUserSave = () => {
    getEvents();
    setshowQRScanner(false);
  };
  const qrScanner =
    showQRScanner && selectedEvent ? (
      <QRScanner
        selectedEvent={selectedEvent}
        handleAddUserSave={handleAddUserSave}
      />
    ) : null;
  return (
    <>
      <Modal show={show} size='4xl' onClose={onCloseModal} popup>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className='w-full'>
            <div className='w-full'>
              <div className='mx-1 flex items-end justify-center'>
                <div className='flex flex-1 justify-center'>
                  <h1 className='text-gray-900 font-bold text-4xl'>
                    {selectedEvent ? 'Edit' : 'Add'} Event
                  </h1>
                </div>
              </div>
            </div>

            <form onSubmit={onSubmit}>
              <div className='text-gray-500'>
                <div className='flex flex-col md:grid md:grid-cols-[20%_80%] text-xl'>
                  <div className='flex'>
                    <div className='font-bold content-center'>Name:</div>
                  </div>
                  <div className='flex'>
                    <div className='w-full'>
                      <TextInput
                        id='name'
                        name='name'
                        type='text'
                        onChange={handleChangeInput}
                        value={formData.name ?? ''}
                        sizing='md'
                        required
                      />
                    </div>
                  </div>
                </div>
                <hr className='bg-gray-300 h-[2px]'></hr>
                <div className='flex flex-col md:grid md:grid-cols-[20%_80%] text-xl'>
                  <div className='flex'>
                    <div className='font-bold content-center'>Event Date:</div>
                  </div>
                  <div className='w-full'>
                    <TextInput
                      id='eventDate'
                      name='eventDate'
                      type='date'
                      onChange={handleChangeInput}
                      value={formatDateInputs(formData.eventDate ?? '')}
                      sizing='md'
                      pattern='\d{4}-\d{2}-\d{2}'
                    />
                  </div>
                </div>
                <hr className='bg-gray-300 h-[2px]'></hr>
                <div className='flex flex-col md:grid md:grid-cols-[20%_80%] text-xl'>
                  <div className='flex'>
                    <div className='font-bold content-center'>Description:</div>
                  </div>
                  <div className=''>
                    <Textarea
                      id='description'
                      name='description'
                      onChange={handleChangeTextArea}
                      value={formData.description ?? ''}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`flex ${
                  selectedEvent ? 'justify-between' : 'justify-end'
                }  pt-2`}
              >
                {selectedEvent && (
                  <Button
                    disabled={buttonDisabled}
                    type='button'
                    className='buttonMainBlue'
                    onClick={() => setshowQRScanner(true)}
                  >
                    <MdOutlinePersonAddAlt size={20} />{' '}
                    <div className='pl-1'>Add User</div>
                  </Button>
                )}
                <Button
                  disabled={buttonDisabled}
                  type='submit'
                  className='buttonMain'
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
          {isLoading && <Loader />}
        </Modal.Body>
      </Modal>
      <Modal show={showQRScanner} size='md' onClose={handleAddUserSave} popup>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div>{qrScanner}</div>
        </Modal.Body>
      </Modal>
    </>
  );
}
