import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { Event } from '../../../Models/Event';
import EventParticipantsListTable from './EventParticipantsListTable';

export default function EventParticipantsModal({
  show,
  setShow,
  selectedEvent,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: Event | null;
}) {
  function onCloseModal() {
    setShow(false);
  }
  return (
    <>
      <Modal show={show} size='4xl' onClose={onCloseModal} popup>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className='w-full'>
            <div className='mx-1 flex items-end justify-center'>
              <div className='flex flex-1 justify-center'>
                <h1 className='text-gray-900 font-bold text-4xl'>
                  Event: {selectedEvent ? selectedEvent.name : 'N/A'}
                </h1>
              </div>
            </div>
            <EventParticipantsListTable
              data={selectedEvent?.eventParticipants ?? []}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
