import { useAppSelector } from '../../Reducers/store';
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Member } from '../../Models/Member';
import { Link } from 'react-router-dom';
import { HiUser, HiXCircle } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import PaginatedTable from '../Common/PaginatedTable';
import { toast } from 'react-toastify';
import MemberApi from '../../Api/MemberApi';

export default function RecentMembers() {
  const { userModel } = useAppSelector((state) => state.user);
  const [data, setData] = useState<Member[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getRecentMembers();
  }, []);

  const getRecentMembers = () => {
    if (userModel) {
      try {
        setData([]);
        setIsLoading(true);
        return MemberApi.getRecentMembers()
          .then((res) => {
            setData(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            toast.error('Error');
            setIsLoading(false);
          });
      } catch (error) {
        console.error(error);
        toast.error('Error');
        setIsLoading(false);
      }
    }
  };

  const columnHelper = createColumnHelper<Member>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          <Link to={`/member/${info.getValue()}`} className='buttonMain p-2'>
            <HiUser size={20} />
          </Link>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.emailAddress, {
      id: 'emailAddress',
      header: 'Email',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.city, {
      id: 'city',
      header: 'Location',
      cell: (info) => (
        <i>
          {info.row.original.city}, {info.row.original.state}
        </i>
      ),
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div className='border-2 border-rose-600 rounded-md'>
      <div className='flex justify-center'>
        <div className='flex items-center space-x-2 font-bold text-gray-500 leading-8 py-2'>
          <span className='tracking-wide uppercase text-lg md:text-3xl'>
            Recent Members
          </span>
        </div>
      </div>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </div>
  );
}
