import { Button, Label, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import UserListTable from './UserListTable';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import { UserListRequest } from '../../Models/UserListRequest';
import MemberApi from '../../Api/MemberApi';
import { User } from '../../Models/User';
import { useAppSelector } from '../../Reducers/store';
import AddUserModal from './AddUserModal';
import { UserLevelNames } from '../../Models/UserLevel';
import { useParams } from 'react-router-dom';
import UserApi from '../../Api/UserApi';
import { BsQrCode } from 'react-icons/bs';
import { RowSelectionState } from '@tanstack/react-table';

export default function UserList() {
  const { id } = useParams();
  const { userModel } = useAppSelector((state) => state.user);
  const [formData, setFormData] = useState<UserListRequest>({
    firstName: '',
    lastName: '',
    emailAddress: '',
  });
  const [userListData, setUserListData] = useState<User[]>([]);
  const [selectedUsers, setselectedUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    getUsers();
  }, [userModel]);

  const getUsers = () => {
    if (userModel) {
      try {
        setIsLoading(true);
        return MemberApi.getMemberUserList(userModel?.memberId ?? 0, formData)
          .then((res) => {
            setUserListData(res.data);

            setIsLoading(false);
          })
          .catch((err) => {
            toast.error('Error');
            setIsLoading(false);
          });
      } catch (error) {
        console.error(error);
        toast.error('Error');
        setIsLoading(false);
      }
    }
  };

  const downloadPdfNow = async () => {
    try {
      setIsLoading(true);
      const response = await UserApi.getUserBadgeList(selectedUsers);
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement('a');
      tempLink.href = url;

      tempLink.setAttribute('download', `User_Badges.pdf`);
      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setIsLoading(false);
    }
  };

  function checkAdminAccess() {
    if (userModel && userModel.userLevelId === UserLevelNames.SuperAdmin) {
      return true;
    } else {
      return false;
    }
  }

  const clearSelection = async () => {
    setRowSelection({});
  };

  return (
    <>
      <div className='w-full'>
        <div className='w-full h-[74px] p-4'>
          <div className='mx-1 flex items-end'>
            <div className='flex flex-1'>
              <h1 className='text-gray-900 font-bold text-4xl'>
                <div>Full User List</div>
              </h1>
            </div>
            {checkAdminAccess() && (
              <div className='absolute flex right-2'>
                <Button
                  onClick={downloadPdfNow}
                  className='buttonMainBlue'
                  disabled={selectedUsers.length === 0}
                >
                  <BsQrCode size={20} className='md:mr-2' />
                  {
                    <span className='hidden md:block'>
                      Download {selectedUsers.length} Badges
                    </span>
                  }
                </Button>
                <button onClick={clearSelection} className='text-blue-600'>
                  Clear
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='w-full p-4'>
          {isLoading && <Loader />}
          <UserListTable
            data={userListData}
            setselectedUsers={setselectedUsers}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </div>
      </div>
    </>
  );
}
