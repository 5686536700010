import React from 'react';
import { Avatar, Button, Dropdown, Navbar, TextInput } from 'flowbite-react';
import emdaLogo from '../../Assets/EMDA150.png';
import { useAppSelector } from '../../Reducers/store';
import { useNavigate } from 'react-router-dom';
import blankImage from '../../Assets/blank.png';
import Search from '../Search/Search';
import { useState } from 'react';
import { SearchRequest } from '../../Models/SearchRequest';
import { SearchResult } from '../../Models/SearchResult';
import SearchApi from '../../Api/SearchApi';
import { toast } from 'react-toastify';
import { MdOutlineMenu } from 'react-icons/md';

export default function TopNav({
  setShow,
}: {
  setShow: (value: React.SetStateAction<boolean>) => void;
}) {
  const navigate = useNavigate();
  const { userModel } = useAppSelector((state) => state.user);

  const logout = () => {
    localStorage.removeItem('EMDAToken');
    navigate('/login');
  };

  const [formData, setFormData] = useState<SearchRequest>({
    searchText: '',
    category: '',
    selectedTagIds: [],
  });
  const [searchData, setSearchData] = useState<SearchResult[]>([]);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      return SearchApi.search(formData)
        .then((res) => {
          setSearchData(res.data);
          setShowSearchResults(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className='w-full'>
        <Navbar fluid className='bg-mainBackground px-4'>
          <div className='flex w-[115px] lg:w-[250px] transition-width transition-slowest'>
            <MdOutlineMenu
              size={45}
              className='pr-2 mr-3 self-end cursor-pointer border-r-2 border-black h-[50px] block lg:hidden'
              onClick={() => setShow(true)}
            />
            <Navbar.Brand href='/'>
              <img src={emdaLogo} className='mr-3 h-9' alt='Member Portal' />
              <div className='hidden lg:block xl:block'>
                <span className='self-center whitespace-nowrap text-xl font-bold dark:text-white'>
                  Member Portal
                </span>
              </div>
            </Navbar.Brand>
          </div>
          <div className='w-[50px] flex order-2'>
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <Avatar
                  alt='User settings'
                  img={userModel?.image?.url ?? blankImage}
                  rounded
                />
              }
            >
              <Dropdown.Header>
                <span className='block text-sm'>
                  {userModel?.firstName} {userModel?.lastName}
                </span>
                <span className='block truncate text-sm font-medium'>
                  {userModel?.emailAddress}
                </span>
              </Dropdown.Header>
              <Dropdown.Item href={`/user/${userModel?.id}`}>
                Profile
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown>
          </div>
          <div className='hidden lg:block lg:w-1/2 xl:w-3/4'>
            <div className='w-full mx-auto p-2'>
              <form onSubmit={onSubmit} className='flex justify-center'>
                <TextInput
                  id='searchText'
                  name='searchText'
                  type='text'
                  placeholder='Search by Member Name or Tag...'
                  onChange={handleChange}
                  value={formData.searchText}
                  className='w-4/5 pr-1'
                />
                <Button type='submit' className='buttonMain'>
                  Search
                </Button>
              </form>
            </div>
          </div>
        </Navbar>
      </div>
      <Search
        show={showSearchResults}
        setShow={setShowSearchResults}
        initialSearchData={searchData}
      />
    </>
  );
}
