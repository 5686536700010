import { Tabs } from 'flowbite-react';
import { useEffect, useState } from 'react';
import blankImage from '../../Assets/blank.png';
import {
  MdLocationPin,
  MdLocalPhone,
  MdEmail,
  MdTag,
  MdSubscriptions,
} from 'react-icons/md';
import {
  FaEarthAmericas,
  FaInstagram,
  FaLinkedin,
  FaSquareFacebook,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa6';
import { RiAdvertisementFill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import MemberApi from '../../Api/MemberApi';
import { Member } from '../../Models/Member';
import { HiClipboardList, HiLightBulb } from 'react-icons/hi';
import { displayDateOnly } from '../../Utils';
import DOMPurify from 'isomorphic-dompurify';
import MemberDisplayAdsListTable from './MemberDisplayAdsListTable';
import { DisplayAd } from '../../Models/DisplayAd';
import DisplayAdsApi from '../../Api/DisplayAdsApi';
import MemberContactListTable from './MemberContactListTable';
import Loader from '../Common/Loader';

export default function MemberProfilePublic() {
  const { id } = useParams();
  const [memberData, setMemberData] = useState<Member | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAds, setDisplayAds] = useState<DisplayAd[]>([]);

  const getMember = () => {
    if (id) {
      setIsLoading(true);
      MemberApi.getMemberPublic(id)
        .then((res) => {
          setMemberData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setMemberData(null);
    }
  };

  const getMemberDisplayAds = () => {
    if (id) {
      setIsLoading(true);
      DisplayAdsApi.getMemberDisplayAdsPublic(id)
        .then((res) => {
          setDisplayAds(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setDisplayAds([]);
    }
  };

  useEffect(() => {
    getMember();
    getMemberDisplayAds();
  }, [id]);

  const iconstyle = { color: '#5b9d30' };

  return (
    <>
      {isLoading && <Loader />}
      <div className='w-full'>
        <div>
          <div className='w-full p-2'>
            <div className='lg:flex no-wrap md:-mx-2 '>
              <div className='w-full lg:w-1/4 md:mx-2'>
                <div className='rounded-md'>
                  <img
                    src={memberData?.image?.url ?? blankImage}
                    alt='...'
                    className='object-contain justify-self-center h-72'
                  />
                </div>
              </div>
              <div className='w-full lg:w-3/4 mx-2'>
                <div className='bg-white p-3'>
                  <div className='flex flex-col lg:flex-row items-end space-x-2 font-bold text-black py-2'>
                    <div className='w-full'>
                      <span className='uppercase text-3xl'>
                        {memberData?.name}
                      </span>
                    </div>
                    <div className='flex w-full justify-end gap-2'>
                      {memberData?.linkedInUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.linkedInUrl.startsWith('https://')
                                ? memberData?.linkedInUrl
                                : 'https://' + memberData?.linkedInUrl
                            }
                          >
                            <FaLinkedin style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                      {memberData?.facebookUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.facebookUrl.startsWith('https://')
                                ? memberData?.facebookUrl
                                : 'https://' + memberData?.facebookUrl
                            }
                          >
                            <FaSquareFacebook style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                      {memberData?.instagramUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.instagramUrl.startsWith('https://')
                                ? memberData?.instagramUrl
                                : 'https://' + memberData?.instagramUrl
                            }
                          >
                            <FaInstagram style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                      {memberData?.twitterUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.twitterUrl.startsWith('https://')
                                ? memberData?.twitterUrl
                                : 'https://' + memberData?.twitterUrl
                            }
                          >
                            <FaTwitter style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                      {memberData?.youTubeUrl && (
                        <div>
                          <a
                            target='_blank'
                            href={
                              memberData?.youTubeUrl.startsWith('https://')
                                ? memberData?.youTubeUrl
                                : 'https://' + memberData?.youTubeUrl
                            }
                          >
                            <FaYoutube style={iconstyle} size={30} />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className='bg-gray-300 h-[5px]'></hr>
                  <div className='text-gray-500'>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdSubscriptions style={iconstyle} />
                        </div>
                        <div className='font-bold'>Member Type:</div>
                      </div>
                      <div className='flex pl-1'>
                        {memberData?.subscription?.name}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocationPin style={iconstyle} />
                        </div>
                        <div className='font-bold'>Address:</div>
                      </div>
                      <div className='pl-1'>
                        {memberData?.city}, {memberData?.state}{' '}
                        {memberData?.country}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdLocalPhone style={iconstyle} />
                        </div>
                        <div className='font-bold'>Phone:</div>
                      </div>
                      <div className='pl-1 text-mainButton'>
                        {memberData?.phone}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <FaEarthAmericas style={iconstyle} />
                        </div>
                        <div className='font-bold'>Website:</div>
                      </div>
                      <div className='pl-1 text-blue-600'>
                        {memberData?.website && (
                          <a
                            target='_blank'
                            href={
                              memberData?.website.startsWith('https://') ||
                              memberData?.website.startsWith('http://')
                                ? memberData?.website
                                : 'https://' + memberData?.website
                            }
                          >
                            {memberData?.website}
                          </a>
                        )}
                      </div>
                    </div>
                    <hr className='bg-gray-300 h-[2px]'></hr>
                    <div className='grid grid-cols-[40%_60%] lg:grid-cols-[25%_75%] py-2 text-xl'>
                      <div className='flex'>
                        <div className='px-1 content-center'>
                          <MdEmail style={iconstyle} />
                        </div>
                        <div className='font-bold content-center'>Email:</div>
                      </div>
                      <div className='pl-1 text-mainButton'>
                        {memberData?.emailAddress}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full p-3'>
          <Tabs aria-label='Tabs with underline' style='underline'>
            <Tabs.Item
              active
              title='Info'
              icon={HiLightBulb}
              className='font-bold'
            >
              <div className='p-3'>
                <div className='grid grid-cols-[40%-60%] lg:grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Description:</div>
                  </div>
                  <div className='pl-1 whitespace-pre-line'>
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            memberData?.description ?? ''
                          ),
                        }}
                      />
                    }
                    {/* {memberData?.description} */}
                  </div>
                </div>
                <div className='grid grid-cols-[40%-60%] lg:grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>EMDA Source:</div>
                  </div>
                  <div className='pl-1'>{memberData?.emdasource}</div>
                </div>
                <div className='grid grid-cols-[40%-60%] lg:grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Lines Represented:</div>
                  </div>
                  <div className='pl-1'>{memberData?.linesRepresented}</div>
                </div>
                <div className='grid grid-cols-[40%-60%] lg:grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Top Brands:</div>
                  </div>
                  <div className='pl-1'>{memberData?.topBrands}</div>
                </div>
                <div className='grid grid-cols-[40%-60%] lg:grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Enrollment Date:</div>
                  </div>
                  <div className='pl-1'>
                    {memberData?.enrollmentDate
                      ? displayDateOnly(new Date(memberData?.enrollmentDate))
                      : ''}
                  </div>
                </div>
                <div className='grid grid-cols-[40%-60%] lg:grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Industry:</div>
                  </div>
                  <div className='pl-1'>{memberData?.industry?.name}</div>
                </div>
                <div className='grid grid-cols-[40%-60%] lg:grid-cols-[10%_90%] py-2'>
                  <div className='flex'>
                    <div className='font-bold'>Established Date:</div>
                  </div>
                  <div className='pl-1'>
                    {memberData?.establishedDate
                      ? displayDateOnly(new Date(memberData?.establishedDate))
                      : ''}
                  </div>
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item
              title='Contacts'
              icon={HiClipboardList}
              className='font-bold'
            >
              <MemberContactListTable />
            </Tabs.Item>
            {memberData && displayAds && displayAds.length > 0 && (
              <Tabs.Item
                title='Ads'
                icon={RiAdvertisementFill}
                className='font-bold'
              >
                <MemberDisplayAdsListTable data={displayAds} />
              </Tabs.Item>
            )}
          </Tabs>
        </div>
      </div>
    </>
  );
}
