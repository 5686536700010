export interface Subscription {
  id: number;
  name: string | null;
  annualFee: number | null;
}

export enum SubscriptionLevelNames {
  DistributorMember = 1,
  RepMember = 2,
  AssociateMember = 3,
  ProviderSilver = 4,
  ProviderGold = 5,
  ProviderPlatinum = 6,
  Deactivated = 7,
  Rejected = 8,
}
