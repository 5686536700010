import { useEffect, useState } from 'react';
import {
  HiOutlineIdentification,
  HiHome,
  HiUsers,
  HiFolderOpen,
  HiOutlineCollection,
  HiCalendar,
  HiOutlinePresentationChartBar,
  HiOutlineSun,
} from 'react-icons/hi'; //https://react-icons.github.io/react-icons/icons/hi/
import { Drawer, Sidebar, Tooltip } from 'flowbite-react';
import { useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import { MdTag } from 'react-icons/md';
import { SitePage } from '../../Models/SitePage';
import SitePageApi from '../../Api/SitePageApi';
import { Link } from 'react-router-dom';
import MemberApi from '../../Api/MemberApi';
import Loader from '../Common/Loader';

export default function SideNav({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}) {
  const { userModel } = useAppSelector((state) => state.user);
  const [pagesData, setPagesData] = useState<SitePage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getSitePages();
  }, []);

  const getSitePages = () => {
    try {
      return SitePageApi.getSitePageListForMenu()
        .then((res) => {
          setPagesData(res.data);
        })
        .catch((err) => {});
    } catch (error) {
      console.error(error);
    }
  };

  function checkAccess() {
    if (userModel && userModel.userLevelId === UserLevelNames.SuperAdmin) {
      return true;
    }
    return false;
  }

  return (
    <>
      {isLoading && <Loader />}
      <Drawer open={show} onClose={handleClose} className='block lg:hidden'>
        <Drawer.Header title='Menu' />
        <Drawer.Items>
          <Sidebar className='[&>div]:bg-transparent [&>div] w-full h-full'>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <div className='flex content-center'>
                  <Sidebar.Item href='/' icon={HiHome}>
                    <div className=''>Dashboard</div>
                  </Sidebar.Item>
                </div>
                <div className='flex content-center'>
                  <Sidebar.Item href='/members' icon={HiOutlineCollection}>
                    <div className=''>Member List</div>
                  </Sidebar.Item>
                </div>
                {userModel &&
                  userModel.userLevelId != UserLevelNames.SuperAdmin && (
                    <div className='flex content-center'>
                      <Sidebar.Item
                        href={`/member/${userModel?.memberId}`}
                        icon={HiOutlineIdentification}
                      >
                        <div className=''>Member Profile</div>
                      </Sidebar.Item>
                    </div>
                  )}
                <div className='flex content-center'>
                  <Sidebar.Item
                    href='/members/fallconvention'
                    icon={HiCalendar}
                  >
                    <div className=''>Fall Convention</div>
                  </Sidebar.Item>
                </div>
                <div className='flex content-center'>
                  <Sidebar.Item
                    href='/members/industryshowcase'
                    icon={HiOutlinePresentationChartBar}
                  >
                    <div className=''>Industry Showcase</div>
                  </Sidebar.Item>
                </div>
                <div className='flex content-center'>
                  <Sidebar.Item
                    href='/members/springmeeting'
                    icon={HiOutlineSun}
                  >
                    <div className=''>Spring Meeting</div>
                  </Sidebar.Item>
                </div>
                <div className='flex content-center'>
                  <Sidebar.Collapse
                    icon={HiFolderOpen}
                    label={'Member Resources'}
                  >
                    {checkAccess() && (
                      <Link to={`/members/resources`}>
                        <div className='w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100'>
                          Manage
                        </div>
                      </Link>
                    )}
                    <Link to='members/directory'>
                      <div className='w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100'>
                        Member Directory
                      </div>
                    </Link>
                    <Link to='member/files'>
                      <div className='w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100'>
                        Download Files
                      </div>
                    </Link>
                    {pagesData &&
                      pagesData.length > 0 &&
                      pagesData.map((page) => (
                        <Link key={page.id} to={`/page/${page.id}`}>
                          <div className='w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100'>
                            {page.title}
                          </div>
                        </Link>
                      ))}
                  </Sidebar.Collapse>
                </div>
                <div className='flex content-center'>
                  <Sidebar.Item href='/members/tags' icon={MdTag}>
                    <div className=''>Search By Tags</div>
                  </Sidebar.Item>
                </div>
                <div className='flex content-center'>
                  <Sidebar.Item href='/users' icon={HiUsers}>
                    <div className=''>User List</div>
                  </Sidebar.Item>
                </div>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>
        </Drawer.Items>
      </Drawer>
      <Sidebar className='[&>div]:bg-transparent [&>div] hidden lg:block'>
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            <div className='flex content-center'>
              <Sidebar.Item href='/' icon={HiHome}>
                <div className=''>Dashboard</div>
              </Sidebar.Item>
            </div>
            <div className='flex content-center'>
              <Sidebar.Item href='/members' icon={HiOutlineCollection}>
                <div className=''>Member List</div>
              </Sidebar.Item>
            </div>
            {userModel &&
              userModel.userLevelId != UserLevelNames.SuperAdmin && (
                <div className='flex content-center'>
                  <Sidebar.Item
                    href={`/member/${userModel?.memberId}`}
                    icon={HiOutlineIdentification}
                  >
                    <div className=''>Member Profile</div>
                  </Sidebar.Item>
                </div>
              )}
            <div className='flex content-center'>
              <Sidebar.Item href='/members/fallconvention' icon={HiCalendar}>
                <div className=''>Fall Convention</div>
              </Sidebar.Item>
            </div>
            <div className='flex content-center'>
              <Sidebar.Item
                href='/members/industryshowcase'
                icon={HiOutlinePresentationChartBar}
              >
                <div className=''>Industry Showcase</div>
              </Sidebar.Item>
            </div>
            <div className='flex content-center'>
              <Sidebar.Item href='/members/springmeeting' icon={HiOutlineSun}>
                <div className=''>Spring Meeting</div>
              </Sidebar.Item>
            </div>
            <div className='flex content-center'>
              <Sidebar.Collapse icon={HiFolderOpen} label={'Member Resources'}>
                {checkAccess() && (
                  <Link to={`/members/resources`}>
                    <div className='w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100'>
                      Manage
                    </div>
                  </Link>
                )}
                <Link to='members/directory'>
                  <div className='w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100'>
                    Member Directory
                  </div>
                </Link>
                <Link to='member/files'>
                  <div className='w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100'>
                    Download Files
                  </div>
                </Link>
                {pagesData &&
                  pagesData.length > 0 &&
                  pagesData.map((page) => (
                    <Link key={page.id} to={`/page/${page.id}`}>
                      <div className='w-full ml-6 p-1 text-wrap rounded-lg hover:bg-gray-100'>
                        {page.title}
                      </div>
                    </Link>
                  ))}
              </Sidebar.Collapse>
            </div>
            <div className='flex content-center'>
              <Sidebar.Item href='/members/tags' icon={MdTag}>
                <div className=''>Search By Tags</div>
              </Sidebar.Item>
            </div>
            <div className='flex content-center'>
              <Sidebar.Item href='/users' icon={HiUsers}>
                <div className=''>User List</div>
              </Sidebar.Item>
            </div>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </>
  );
}
