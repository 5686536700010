import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { File } from '../../Models/File';
import { HiDownload, HiXCircle } from 'react-icons/hi';
import { useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import { useParams } from 'react-router-dom';

export default function FileListTable({
  data,
  deleteFile,
}: {
  data: File[];
  deleteFile: (id: number) => void;
}) {
  const { id } = useParams();
  const { userModel } = useAppSelector((state) => state.user);

  const columnHelper = createColumnHelper<File>();

  const columns = [
    columnHelper.accessor((row) => row.url, {
      id: 'download',
      header: '',
      cell: (info) => (
        <a href={info.row.original.url!} download={info.row.original.name}>
          <button
            className='p-2'
            disabled={info.row.original.url === null ? true : false}
            style={{ color: 'blue' }}
          >
            <HiDownload size={20} />
          </button>
        </a>
      ),
      footer: (info) => info.column.id,
      enableColumnFilter: false,
      size: 35,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
      //filterFn: 'includesString',
      // filterFn: (row, id, value) => {
      //   return value.includes(row.getValue(id));
      // },
    }),
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      header: 'Description',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
      // filterFn: (row, id, value) => {
      //   return value.includes(row.getValue(id));
      // },
    }),
    columnHelper.accessor((row) => row.typeName, {
      id: 'typeName',
      header: 'Type',
      // cell: (info) => <i>{info.row.original.type?.name}</i>,
      footer: (info) => info.column.id,
      // filterFn: (row, id, value) => {
      //   return value.includes(row.original.type?.name);
      // },
    }),
    // columnHelper.accessor((row) => row.url, {
    //   id: 'url',
    //   header: 'url',
    //   // cell: (info) => <i>{info.getValue()}</i>,
    //   footer: (info) => info.column.id,
    // }),
    columnHelper.accessor((row) => row.id, {
      id: 'delete',
      header: '',
      cell: (info) => (
        <div className='flex justify-center'>
          {id &&
            userModel &&
            userModel.userLevelId === UserLevelNames.MemberAdmin &&
            userModel.memberId === Number.parseInt(id) && (
              <button
                className='buttonCancel p-2'
                onClick={() => deleteFile(info.getValue())}
              >
                <HiXCircle size={20} />
              </button>
            )}
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
