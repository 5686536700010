import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Link } from 'react-router-dom';
import { HiUser } from 'react-icons/hi';
import { Member } from '../../Models/Member';
import React, { useEffect } from 'react';
import { useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import { Checkbox } from 'flowbite-react';

export default function MemberListTable({
  data,
  showSelection,
  setselectedMembers,
  rowSelection,
  setRowSelection,
}: {
  data: Member[];
  showSelection: boolean;
  setselectedMembers: React.Dispatch<React.SetStateAction<Member[]>>;
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
}) {
  const { userModel } = useAppSelector((state) => state.user);

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setselectedMembers(
      table.getSelectedRowModel()?.flatRows.map((r) => r.original)
    );
  };

  function checkAdminAccess() {
    if (userModel && userModel.userLevelId === UserLevelNames.SuperAdmin) {
      return true;
    } else {
      return false;
    }
  }
  const columnHelper = createColumnHelper<Member>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: ({ table }) => (
        <div className='flex justify-center'>
          {checkAdminAccess() && showSelection && (
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              className='justify-center p-2'
            />
          )}
        </div>
      ),
      cell: ({ row }) => (
        <div className='flex justify-center'>
          {checkAdminAccess() && showSelection && (
            <Checkbox
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
              className='self-center p-2 mr-2'
            />
          )}
          <Link to={`/member/${row.original.id}`} className='buttonMain p-2'>
            <HiUser></HiUser>
          </Link>
        </div>
      ),
      footer: (info) => info.column.id,
      size: 50,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.subscription?.name, {
      id: 'subscriptionId',
      header: 'Subscription',
      // cell: (info) => <i>{info.row.original.subscription?.name}</i>,
      // filterFn: 'includesString',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.website, {
      id: 'website',
      header: 'Website',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: 'phone',
      header: 'Phone',
      cell: (info) => info.getValue() ?? '',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.emailAddress, {
      id: 'emailAddress',
      header: 'Email',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    enableMultiRowSelection: true,
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
