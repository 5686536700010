import { format, isDate } from 'date-fns';
import React from 'react';

export const displayDateOnly = (dateValOrString: Date | null) => {
  let output;
  let dateString: string;
  const dateFormat = RegExp(/\d{2}\/\d{2}\/\d{4}/);
  const dateFormat2 = RegExp(/\d{4}-\d{2}-\d{2}/);
  if (dateValOrString === null) {
    return '';
  } else if (isDate(dateValOrString) && dateValOrString instanceof Date) {
    /// 2000-01-01T05:00:00.000Z
    dateString = format(
      dateValOrString as unknown as Date | number,
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  } else {
    dateString = dateValOrString as string;
  }

  if (!dateString) {
    output = '';
    return output;
  }

  if (dateString.includes('T')) {
    const [datePart, ...rest] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    output = `${month}/${day}/${year}`;
    return output;
  }

  if (dateFormat.test(dateString)) {
    output = dateString;
    return output;
  } else if (dateFormat2.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    output = `${month}/${day}/${year}`;
    return output;
  }
  return output;
};

export const formatDateInputs = (value: string) => {
  const dateFormat2 = RegExp(/\d{4}-\d{2}-\d{2}/);
  let input = value || '';

  if (input && input.includes('T')) {
    const [dParts, tParts] = input.split('T');
    const [year, month, day] = dParts.split('-');
    input = `${day}/${month}/${year}`;
    // input = `${year}/${month}/${day}`;
  } else {
    const [year, month, day] = input.split('-');
    input = `${day}/${month}/${year}`;
  }

  input = input.replace(/\D/g, '');

  input = input.substring(0, 12);
  const size = input.length;

  if (size >= 8) {
    // return `${input.substring(0, 2)}-${input.substring(2, 4)}-${input.substring(4)}`;
    const test = `${input.substring(4, 8)}-${input.substring(
      2,
      4
    )}-${input.substring(0, 2)}`;
    return `${input.substring(4, 8)}-${input.substring(2, 4)}-${input.substring(
      0,
      2
    )}`;
  }
};

export const isNumber = (value: any) => {
  return typeof value === 'number';
};

export const onlyNumberInput = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const key = event.key;
  if (
    key &&
    key !== 'Backspace' &&
    key !== 'Tab' &&
    key !== 'ArrowLeft' &&
    key !== 'ArrowRight' &&
    key !== '.' &&
    !/[0-9]/.test(event.key)
  ) {
    return true;
  }
  return false;
};

export const cleanMoney = (value: string) =>
  value.length > 0 ? value.replace(/^(-)|[^0-9.]+/g, '$1') : value ?? '';

export const formatNumbers = (value: any) => {
  if (!value) {
    return value;
  }
  const hasSeparator = `${value}`.includes('.');
  const [before, after] = `${value}`.split('.');

  let formattedBefore = '';
  let formattedAfter = '';

  if (before !== '-') {
    formattedBefore = new Intl.NumberFormat().format(+cleanMoney(before));
  }
  if (before === '-') {
    formattedBefore = before;
  }
  if (after !== '' && !isNaN(+after)) {
    const afterString = '0.' + after;
    formattedAfter = afterString;
  }
  if (formattedAfter) {
    return `${formattedBefore}${formattedAfter.slice(1).substring(0, 3)}`;
  }
  if (hasSeparator) {
    return `${formattedBefore}.`;
  }
  return formattedBefore ?? '';
};

export const renderHTML = (rawHTML: string) =>
  React.createElement('div', {
    dangerouslySetInnerHTML: { __html: decodeHtml(rawHTML) },
  });

function decodeHtml(html: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}
