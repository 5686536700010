import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import MemberApi from '../../Api/MemberApi';
import { useAppSelector } from '../../Reducers/store';
import MemberListTable from './MemberListTable';
import { Member } from '../../Models/Member';
import ReactGA from 'react-ga4';
import { RowSelectionState } from '@tanstack/react-table';
import { UserLevelNames } from '../../Models/UserLevel';
import { Button } from 'flowbite-react';
import { FaMapMarkedAlt } from 'react-icons/fa';
ReactGA.send({ hitType: 'pageview', page: '/members', title: 'Member List' });

export default function MemberList() {
  const { userModel } = useAppSelector((state) => state.user);
  const [memberListData, setMemberListData] = useState<Member[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [selectedMembers, setselectedMembers] = useState<Member[]>([]);

  useEffect(() => {
    getMembers();
  }, [userModel]);

  const getMembers = () => {
    try {
      setIsLoading(true);
      return MemberApi.getMembers()
        .then((res) => {
          setMemberListData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error('Error');
          console.error(err);
          setIsLoading(false);
        });
    } catch (error) {
      toast.error('Error');
      console.error(error);
      setIsLoading(false);
    }
  };

  const downloadPdfNow = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.getMemberTerritories(selectedMembers);
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', `Member_Territories.pdf`);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setIsLoading(false);
    }
  };

  const clearSelection = async () => {
    setRowSelection({});
  };

  function checkAdminAccess() {
    if (userModel && userModel.userLevelId === UserLevelNames.SuperAdmin) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className='w-full'>
      <div className='w-full h-[74px] p-4'>
        <div className='mx-1 flex items-end'>
          <div className='flex flex-1'>
            <h1 className='text-gray-900 font-bold text-4xl'>Member List</h1>
          </div>
          {checkAdminAccess() && (
            <div className='absolute flex right-2'>
              <Button
                onClick={downloadPdfNow}
                className='buttonMainBlue'
                disabled={selectedMembers.length === 0}
              >
                <FaMapMarkedAlt size={20} className='md:mr-2' />
                {
                  <span className='hidden md:block'>
                    Download {selectedMembers.length}{' '}
                    {selectedMembers.length === 1 ? 'Territory' : 'Territories'}
                  </span>
                }
              </Button>
              <button onClick={clearSelection} className='text-blue-600'>
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='w-full p-4'>
        {isLoading && <Loader />}
        <MemberListTable
          data={memberListData}
          showSelection={true}
          setselectedMembers={setselectedMembers}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </div>
    </div>
  );
}
