import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../../Common/PaginatedTable';
import { Event } from '../../../Models/Event';
import { displayDateOnly } from '../../../Utils';
import { HiPencilAlt, HiXCircle } from 'react-icons/hi';
import { Button } from 'flowbite-react';
import { MdOutlinePersonAddAlt } from 'react-icons/md';
import { EventParticipant } from '../../../Models/EventParticipant';

export default function EventParticipantsListTable({
  data,
}: // handleEditEvent,
// handleAddUser,
// deleteEvent,
{
  data: EventParticipant[];
  // handleEditEvent: (ev: Event) => void;
  // handleAddUser: (ev: Event) => void;
  // deleteEvent: (ev: Event) => void;
}) {
  const columnHelper = createColumnHelper<EventParticipant>();

  const columns = [
    // columnHelper.accessor((row) => row.id, {
    //   id: 'id',
    //   header: '',
    //   cell: (info) => (
    //     <div className='flex justify-center'>
    //       <button
    //         className='buttonMain p-2'
    //         onClick={() => handleEditEvent(info.row.original)}
    //       >
    //         <HiPencilAlt size={20} />
    //       </button>
    //       <button
    //         onClick={() => handleAddUser(info.row.original)}
    //         className='buttonMainBlue p-2'
    //       >
    //         <MdOutlinePersonAddAlt size={20} />
    //       </button>
    //     </div>
    //   ),
    //   footer: (info) => info.column.id,
    //   size: 100,
    //   enableColumnFilter: false,
    // }),
    columnHelper.accessor((row) => row.user?.firstName, {
      id: 'firstName',
      header: 'First',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.user?.lastName, {
      id: 'lastName',
      header: 'Last',
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    // columnHelper.accessor((row) => row.dateCreated, {
    //   id: 'dateCreated',
    //   header: 'Date',
    //   cell: (info) =>
    //     info.getValue() === null
    //       ? ''
    //       : displayDateOnly(new Date(info.getValue()!)),
    //   footer: (info) => info.column.id,
    //   filterFn: (row, id, value) => {
    //     return value.includes(row.getValue(id));
    //   },
    //   sortingFn: 'alphanumeric',
    // }),
    // columnHelper.accessor((row) => row.id, {
    //   id: 'delete',
    //   header: '',
    //   cell: (info) => (
    //     <div className='flex justify-center'>
    //       <button
    //         className='buttonCancel p-2'
    //         onClick={() => deleteEvent(info.row.original)}
    //       >
    //         <HiXCircle size={20} />
    //       </button>
    //     </div>
    //   ),
    //   footer: (info) => info.column.id,
    //   size: 50,
    //   enableColumnFilter: false,
    // }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [
        {
          id: 'eventDate',
          desc: true,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
