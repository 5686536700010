import { User } from '../../Models/User';
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import PaginatedTable from '../Common/PaginatedTable';
import { Link } from 'react-router-dom';
import { HiUser } from 'react-icons/hi';
import UserApi from '../../Api/UserApi';
import { Button, Checkbox } from 'flowbite-react';
import { BsQrCode } from 'react-icons/bs';
import { useAppSelector } from '../../Reducers/store';
import { UserLevelNames } from '../../Models/UserLevel';
import { useEffect, useState } from 'react';

export default function UserListTable({
  data,
  setselectedUsers,
  rowSelection,
  setRowSelection,
}: {
  data: User[];
  setselectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  rowSelection: RowSelectionState;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
}) {
  const { userModel } = useAppSelector((state) => state.user);

  const downloadPdfNow = async (u: User) => {
    try {
      const response = await UserApi.getUserBadge(u.id);
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute(
        'download',
        `${u.firstName}_${u.lastName}_Badge.pdf`
      );

      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  useEffect(() => {
    handleRowSelectionChange();
  }, [rowSelection]);

  const handleRowSelectionChange = () => {
    setselectedUsers(
      table.getSelectedRowModel().flatRows.map((r) => r.original)
    );
  };

  function checkAdminAccess() {
    if (userModel && userModel.userLevelId === UserLevelNames.SuperAdmin) {
      return true;
    } else {
      return false;
    }
  }

  const columnHelper = createColumnHelper<User>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: ({ table }) => (
        <div className='flex justify-center'>
          {checkAdminAccess() && (
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              className='justify-center p-2'
            />
          )}
        </div>
      ),
      cell: ({ row }) => (
        <div className='flex justify-center'>
          {checkAdminAccess() && (
            <Checkbox
              checked={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
              className='self-center p-2 mr-2'
            />
          )}
          <Link to={`/user/${row.original.id}`} className='buttonMain p-2'>
            <HiUser></HiUser>
          </Link>
          {checkAdminAccess() && (
            <button
              className='buttonMainBlue p-2'
              onClick={() => downloadPdfNow(row.original)}
            >
              <BsQrCode />
            </button>
          )}
        </div>
      ),
      footer: (info) => info.column.id,
      size: 75,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.firstName, {
      id: 'firstName',
      header: 'First Name',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'lastName',
      header: 'Last Name',
      sortingFn: 'alphanumeric',
      // cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.emailAddress, {
      id: 'emailAddress',
      header: 'Email',
      // cell: (info) => <i>{info.getValue()}</i>,
      sortingFn: 'alphanumeric',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.phoneNumber, {
      id: 'phoneNumber',
      header: 'Phone',
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.member?.name, {
      id: 'memberId',
      header: 'Member',
      cell: (info) => (
        <i>{info.getValue() ? info.row.original.member?.name : 'EMDA'}</i>
      ),
      footer: (info) => info.column.id,
      filterFn: 'includesString',
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [
        {
          id: 'lastName',
          desc: false,
        },
      ],
    },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    enableMultiRowSelection: true,
  });

  return (
    <>
      <div className='w-full mx-auto overflow-x-auto'>
        <PaginatedTable
          table={table}
          dataCount={data.length}
          columnResizeMode='onChange'
          showFilters={true}
        />
      </div>
    </>
  );
}
